import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/app/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter-tight\"}],\"variableName\":\"interTight\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../styles/fonts/EditorsNote/EditorsNote-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../styles/fonts/EditorsNote/EditorsNote-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-editors-note\"}],\"variableName\":\"editorsNote\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/_analytics/page-view-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/app/src/app/_analytics/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPrivyProvider"] */ "/app/src/app/_auth/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/src/app/components/layout/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/app/components/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
