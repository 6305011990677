'use client';

import { Search } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useEnsAddress } from 'wagmi';
import { normalize } from 'viem/ens';
export function VerifyEns() {
  const [searchText, setSearchText] = useState<string>('');
  const [isEnsName, setIsEnsName] = useState<boolean>(false);
  const {
    data: ensAddress,
    isLoading
  } = useEnsAddress({
    name: isEnsName ? normalize(searchText) : undefined,
    query: {
      enabled: isEnsName
    }
  });
  useEffect(() => {
    setIsEnsName(searchText.toLowerCase().endsWith('.eth'));
  }, [searchText]);
  return <form role="search" className="absolute left-28 top-[calc(100%-0.5rem)] z-40 w-[60rem] px-11" data-sentry-component="VerifyEns" data-sentry-source-file="verify-ens.tsx">
      <div className="backdrop-blur-6xl flex w-full items-center overflow-hidden rounded-full bg-white/30 px-4 py-2">
        <Search size={28} className="mr-2 shrink-0 text-white" data-sentry-element="Search" data-sentry-source-file="verify-ens.tsx" />
        <input type="text" value={searchText} onChange={e => setSearchText(e.target.value)} placeholder="search or connect your wallets" aria-label="search" autoFocus spellCheck="false" className={`font-display -mb-2 -mt-4 w-full min-w-0 cursor-[3px] bg-transparent text-6xl caret-white shadow-none outline-none placeholder:text-gray-300/20 focus:ring-0 ${isEnsName ? ensAddress ? 'text-green-400' : isLoading ? 'text-yellow-400' : 'text-red-400' : 'text-white'}`} />
      </div>
    </form>;
}