'use client';

import { Link } from 'next-view-transitions';
import { Search, X, CircleUser } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { UserPill } from '@privy-io/react-auth/ui';

// import { useMode } from '~/app/hooks/use-mode';

import { OUTPAINT_FARCASTER_CHANNEL } from '~/utils/constants';
import { ActionButton } from '~/app/components/ui/button';
import Wordmark from '~/assets/wordmark.svg';
import { ModeSwitcher } from '../mode-switcher';
import { VerifyEns } from '../verify-ens';
import dynamic from 'next/dynamic';
import { useAccount, useEnsName } from 'wagmi';
const ThemeSwitcher = dynamic(() => import('../theme-switcher').then(mod => mod.ThemeSwitcher), {
  ssr: false
});
export function Navbar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const {
    login
  } = usePrivy();
  // const { mode } = useMode();
  const {
    ready,
    user,
    authenticated
  } = usePrivy();
  if (!ready) {
    return null;
  }
  return <section className="relative" data-sentry-component="Navbar" data-sentry-source-file="navbar.tsx">
      <nav className="flex-start sticky top-0 z-50 flex items-center justify-between bg-transparent px-11 py-4">
        <div className="flex items-center">
          <Link href="/" className="-mb-[4px] mr-8 flex-shrink-0" data-sentry-element="Link" data-sentry-source-file="navbar.tsx">
            <Wordmark className="h-[21px] w-auto text-black dark:text-white" data-sentry-element="Wordmark" data-sentry-source-file="navbar.tsx" />
          </Link>
        </div>

        <div className="flex flex-1 items-center justify-between">
          <div className="flex items-center rounded-full bg-white bg-white/50 py-[1px] pl-1 pr-2 backdrop-blur-2xl">
            <NavLink openInNewTab href={OUTPAINT_FARCASTER_CHANNEL} data-sentry-element="NavLink" data-sentry-source-file="navbar.tsx">
              Follow on Farcaster
            </NavLink>
            {authenticated ? <NavLink href="/design">Design</NavLink> : null}
            <NavLink href="/customer-service" data-sentry-element="NavLink" data-sentry-source-file="navbar.tsx">Customer Service</NavLink>
            {/* <button
              className="rounded-full p-1 pl-1 hover:bg-black/10"
              onClick={() => setIsSearchOpen(!isSearchOpen)}
             >
              {isSearchOpen ? (
                <X size={14} className="text-gray-700 hover:text-gray-900" />
              ) : (
                <Search size={14} className="text-gray-700 hover:text-gray-900" />
              )}
             </button> */}
          </div>
        </div>
        {/* <div className="flex flex-1 items-center justify-end gap-2">
          <ModeSwitcher />
          <ThemeSwitcher />
          {authenticated ? (
            <PrivyProfileDebugger />
          ) : (
            <NavButton onClick={login}>Connect</NavButton>
          )}
         </div> */}
      </nav>

      {isSearchOpen && <VerifyEns />}
    </section>;
}
export function NavLink({
  href,
  children,
  openInNewTab = false
}: {
  href: string;
  children: React.ReactNode;
  openInNewTab?: boolean;
}) {
  const linkProps = openInNewTab ? {
    target: '_blank',
    rel: 'noopener noreferrer'
  } : {};
  const pathname = usePathname();
  const isActive = (path: string) => pathname === path;
  const active = isActive(href);
  return <Link href={href} {...linkProps} data-sentry-element="Link" data-sentry-component="NavLink" data-sentry-source-file="navbar.tsx">
      <div className={`rounded-full px-2 hover:bg-black/10 ${active ? 'bg-black' : ''}`}>
        <p className={`font-display py-[3px] text-sm tracking-wide ${active ? 'text-white' : 'text-black hover:text-gray-900'}`}>
          {children}
        </p>
      </div>
    </Link>;
}
export function NavButton({
  onClick,
  children
}: {
  onClick: () => void;
  children: React.ReactNode;
}) {
  return <button onClick={onClick} className="rounded-full bg-white/50 px-2 hover:bg-black/10" data-sentry-component="NavButton" data-sentry-source-file="navbar.tsx">
      <p className="font-display py-[3px] text-sm tracking-wide text-black hover:text-gray-900">
        {children}
      </p>
    </button>;
}
export function PrivyProfileDebugger() {
  return <UserPill expanded={false} label={<CircleUser size={16} className="text-black hover:text-gray-900" />} size={28} data-sentry-element="UserPill" data-sentry-component="PrivyProfileDebugger" data-sentry-source-file="navbar.tsx" />;
}