'use client';

import { useRouter } from 'next/navigation';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type PrivyClientConfig, PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider, createConfig } from '@privy-io/wagmi';
import { http } from 'viem';
import { mainnet, base, optimism, arbitrum, polygon
// optional testnet chains if needed
// baseGoerli,
// optimismGoerli,
// arbitrumGoerli,
// polygonMumbai,
} from 'viem/chains';
const queryClient = new QueryClient();
const supportedChains = [mainnet,
// l1
base,
// op stack
optimism,
// op stack
arbitrum,
// arbitrum (different rollup tech)
polygon // polygon pos (different scaling solution)
];
const transports = {
  [mainnet.id]: http(),
  [base.id]: http(),
  [optimism.id]: http(),
  [arbitrum.id]: http(),
  [polygon.id]: http()
};
export const wagmiConfig = createConfig({
  chains: supportedChains,
  transports
});
const privyConfig: PrivyClientConfig = {
  loginMethods: ['farcaster', 'wallet'],
  defaultChain: base,
  supportedChains: supportedChains,
  appearance: {
    theme: 'light',
    accentColor: '#FF3B00',
    landingHeader: '',
    showWalletLoginFirst: false
  }
};
export function CSPrivyProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  return <PrivyProvider appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID || ''} config={privyConfig} onSuccess={() => router.push('/design')} data-sentry-element="PrivyProvider" data-sentry-component="CSPrivyProvider" data-sentry-source-file="provider.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="provider.tsx">
        <WagmiProvider config={wagmiConfig} data-sentry-element="WagmiProvider" data-sentry-source-file="provider.tsx">{children}</WagmiProvider>
      </QueryClientProvider>
    </PrivyProvider>;
}